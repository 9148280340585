<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CForm id="importForm">
        <CCard>
          <CCardHeader>
            <strong>{{ $t('channel.importChannel') }}</strong>
          </CCardHeader>
          <CCardBody>
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">{{ $t('channel.selectFile') }}</label>
              <div class="col-sm-9">
                <b-form-file v-model="channel.file" :placeholder="$t('channel.fileToImport')" lang="ru" class="mb-3"
                  required @change="fileChange" :browse-text="$t('common.browse')"></b-form-file>
              </div>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton :disabled="!canSubmitForm" type="submit" @click.prevent="readFile" color="success">
              <CIcon name="cil-check-circle" /> {{ $t('channel.addChannel') }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import utilMessages from "../../../utils/messages"

export default {
  name: 'ChannelImport',
  components: {
    BFormFile
  },
  data() {
    return {
      channelId: null,
      channel: {
        file: null
      },

    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.channelId = this.$route.params.id
    }
  },
  methods: {
    fileChange($event) {
      this.channel.file = $event.target.files[0]
    },

    readFile() {

      const { file } = this.channel
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        console.log('result', reader.result);
        const json = reader.result
        this.importChannel(json)
      };

      reader.onerror = function () {
        this.$toast.error(utilMessages.errMessage(this.$i18n.t('common.fileReadError'), reader.error))
      };

    },

    async importChannel(json) {
      try {
        const data = JSON.parse(json)
        const channel = await this.$store.dispatch('importChannel', data)
        this.$toast.success(this.$i18n.t('channel.channelImported'))
        this.$router.push({ path: `/channels/${channel.id}` })
      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    }
  },
  computed: {
    canSubmitForm() {
      return this.channel.file !== null;
    }
  }
}
</script>
